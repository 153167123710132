import React from "react"

const SocialButton = (prop) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={prop.href}
      className="social-icon"
    >
      {prop.icon}
    </a>
  )
}

export default SocialButton
