import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Grid, Container } from "@mui/material"
import SocialButton from "../components/socialbutton"
import { FaInstagram } from "react-icons/fa"
import { BsFillHeartFill } from "react-icons/bs"
import Commetns from "../images/svgs/comments.svg"
import Next from "../images/svgs/next.svg"
import ScrollAnimation from "react-animate-on-scroll"
import { getSrc } from "gatsby-plugin-image"

const InstagramSection = () => {
  return (
    <section className="instagram">
      {/*<Container>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h2>Follow Us On Instagram</h2>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__fadeIn"
          delay={200}
          animateOnce={true}
        >
          <div className="subtitle">
            <SocialButton
              href="https://www.instagram.com/jandakothelico/"
              icon={<FaInstagram />}
            />
            <span className="handle">/jandakothelico</span>
          </div>
        </ScrollAnimation>
      </Container>

			<Grid container className="allPosts" spacing={1}>
        <StaticQuery
          query={graphql`
            query MyInstaQuery {
              allInstaNode(sort: { fields: timestamp, order: DESC }) {
                edges {
                  node {
                    likes
                    id
                    comments
                    permalink
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 700
                          quality: 100
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
          `}
          render={(data) => (
            <>
              {data.allInstaNode.edges.map((post, index) => {
                return (
                  <Grid
                    item
                    className="instaPost"
                    xs={12}
                    sm={12 / 3}
                    md={12 / 4}
                    lg={12 / 5}
                    key={index}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={post.node.permalink || "#"}
                    >
                      <ScrollAnimation
                        animateIn="animate__fadeInUp"
                        delay={100 + index * 45}
                        animateOnce={true}
                      >
                        <div
                          className="instaBG"
                          style={{
                            //  backgroundImage: `url(${post?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src})`,
                            backgroundImage: `url(${getSrc(
                              post.node.localFile
                            )})`,
                          }}
                        >
                          <div className="details">
                            <div className="details__box">
                              <div className="heart">
                                <BsFillHeartFill />
                                <span className="likes">{post.node.likes}</span>
                              </div>
                              <div className="comment">
                                <Commetns />
                                <span className="text">
                                  {post.node.comments}
                                </span>
                              </div>
                            </div>
                            <div className="viewDetails">
                              <span className="btnText">View Details</span>
                              <Next />
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </a>
                  </Grid>
                )
              })}
            </>
          )}
        />
      </Grid>*/}
    </section>
  )
}

export default InstagramSection
