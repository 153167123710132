import React from "react"
import { Container, Grid } from "@mui/material"
import DetailBlock from "../components/detailblock"
import Phone from "../images/svgs/phone.svg"
import { BsFillClockFill } from "react-icons/bs"
import Loc from "../images/svgs/loc.svg"
import Bg from "../images/svgs/details.svg"
import ScrollAnimation from "react-animate-on-scroll"

const Details = () => {
  return (
    <div className="details">
      <Bg className="bgSvg1" />
      <Bg className="bgSvg2" />
      <Container>
        <Grid container spacing={10} className="detailContainer">
          <Grid item xs={12} md={4} className="detail">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <DetailBlock
                title="Contact Us"
                text={
                  <ul>
                    <li>
                      <a href="tel:+61474350663">0474 350 663</a>
                    </li>
                    <li>
                      <a href="mailto:office@jandakotheli-co.com.au">
                        office@jandakotheli-co.com.au
                      </a>
                    </li>
                  </ul>
                }
                icon={<Phone />}
              />
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} md={4} className="detail">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <DetailBlock
                title="Open 7 Days A Week"
                text={
                  <ul>
                    <li>Monday-Friday 8am-5pm</li>
                    <li>Saturday 8am-4pm</li>
                    <li>Sundays 9am-4pm</li>
                  </ul>
                }
                icon={<BsFillClockFill />}
              />
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} md={4} className="detail">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <DetailBlock
                title="Find Us"
                text={
                  <ul>
                    <li>
                      <a href="https://goo.gl/maps/LCLSsahX8vySoocF6">
                        8 Bell Court, Jandakot Airport
                      </a>
                    </li>
                    <li>
                      <a href="https://goo.gl/maps/LCLSsahX8vySoocF6">
                        Western Australia, 6164
                      </a>
                    </li>
                  </ul>
                }
                icon={<Loc />}
              />
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Details
