import React, { useState } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Logo from "../images/logo.png"
import { FaInstagram, FaFacebookF } from "react-icons/fa"
import Phone from "../images/svgs/phone.svg"
import { BsChevronDown } from "react-icons/bs"
import { Container } from "@mui/material"
import SocialButton from "./socialbutton"

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  const handleMenuClick = () => {
    setToggleMenu(!toggleMenu)
    if (!toggleMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }

  const handleLinkClick = () => {
    document.body.style.overflow = "visible"
  }

  return (
    <section className="header">
      <Container
        className={
          toggleMenu ? "header__container nav--active" : "header__container"
        }
      >
        <div
          className={toggleMenu ? "header__logo nav--active" : "header__logo"}
        >
          <Link to="/">
            <img src={Logo} alt="Jandakot Heli-Co" className="nav-logo" />
          </Link>
        </div>
        <nav className="header__nav">
          <span
            aria-hidden="true"
            className={
              toggleMenu
                ? "header__nav__hamburger nav--active"
                : "header__nav__hamburger"
            }
            role="button"
            onClick={handleMenuClick}
          >
            <span></span>
          </span>
          <div className="menu__wrapper">
            <ul className="main__nav">
              <StaticQuery
                query={graphql`
                  query MenuQuery {
                    allWpMenu(filter: { id: { eq: "dGVybTo3" } }) {
                      edges {
                        node {
                          id
                          menuItems {
                            nodes {
                              path
                              parentId
                              label
                              cssClasses
                              childItems {
                                nodes {
                                  path
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `}
                render={(data) => (
                  <>
                    {data.allWpMenu.edges[0].node.menuItems.nodes
                      .filter((item) => {
                        return item.parentId == null
                      })
                      .map((item, index) => (
                        <li
                          onClick={handleLinkClick}
                          key={index}
                          className={
                            item.childItems.nodes.length !== 0
                              ? "dropdown"
                              : "" + item.cssClasses.length
                              ? item.cssClasses.join(" ")
                              : ""
                          }
                        >
                          <Link to={item.path}>
                            {item.label}
                            {item.childItems.nodes.length !== 0 ? (
                              <BsChevronDown />
                            ) : (
                              ""
                            )}
                          </Link>
                          {item.childItems.nodes.length !== 0 ? (
                            <ul className="dropdown__menu">
                              {item.childItems.nodes.map((item, index) => (
                                <li
                                  key={index}
                                  className="dropdown__menu__item"
                                >
                                  <Link to={item.path}>{item.label}</Link>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                  </>
                )}
              />
            </ul>
          </div>
        </nav>
        <div className={toggleMenu ? "socials nav--active" : "socials"}>
          <SocialButton
            href="https://www.instagram.com/jandakothelico/"
            icon={<FaInstagram />}
          />
          <SocialButton
            href="https://www.facebook.com/jandakothelico/"
            icon={<FaFacebookF />}
          />
          <SocialButton href="tel:+61474350663" icon={<Phone />} />
        </div>
      </Container>
    </section>
  )
}

export default Header
