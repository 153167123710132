import React from "react"

import Details from "../components/details"
import FooterCont from "../components/footer-cont"

const Footer = () => {
  return (
    <footer>
      <Details />
      <FooterCont />
    </footer>
  )
}

export default Footer
