import React, { useEffect } from "react"
import icon from "../images/mapIcon.png"

export default function Map() {
  function getWindowWidth() {
    const { innerWidth: width } = window
    return width
  }
  useEffect(() => {
    const initMap = () => {
      //locations
      const donutShop_1 = { lat: -32.08842499916629, lng: 115.88586675809209 } //victoria park
      const screenWidth = getWindowWidth()
      const zoom = screenWidth < 768 ? 12.3 : 13.3
      const center = { lat: -32.08842499916629, lng: 115.88586675809209 }

      const map = window?.google?.maps
        ? new window.google.maps.Map(document.getElementById("map"), {
            center,
            zoom,
          })
        : null

      //info window
      // const infowindow_1 = new window.google.maps.InfoWindow({
      //   content: `<h3 class="m-0">Donut Worry</h1>
      //             <p class="m-0">865 Albany Hwy, East Victoria Park WA 6101</p>
      //             <a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" href="https://goo.gl/maps/z3H3TYhVh3J3RqCcA">Get Directions</a>`,
      // })

      if (map) {
        // eslint-disable-next-line no-unused-vars
        const marker_1 = new window.google.maps.Marker({
          position: donutShop_1,
          map,
          icon: icon,
        })
        marker_1.addListener("click", () => {
          const url = "https://goo.gl/maps/LCLSsahX8vySoocF6"
          window.open(url, "_blank").focus()
        })
        // marker_1.addListener("click", () => {
        //   infowindow_1.open({
        //     anchor: marker_1,
        //     map,
        //     shouldFocus: false,
        //   })
        // })
      }
    }

    initMap()
  }, [])

  return <div id="map" />
}
