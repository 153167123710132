import React from "react"

import { Grid } from "@mui/material"

const DetailBlock = (prop) => {
  return (
    <Grid container justifyContent="center" className="detail-block">
      <Grid item className="detail-icon">
        {prop.icon}
      </Grid>
      <Grid item xs="auto" md={12} className="detail-cont">
        <h4>{prop.title}</h4>
        <div>{prop.text}</div>
      </Grid>
    </Grid>
  )
}

export default DetailBlock
