import React from "react"
import { Link } from "gatsby"

import { Container, Grid } from "@mui/material"
import Map from "../components/map"
import SocialButton from "../components/socialbutton"
import { FaInstagram } from "react-icons/fa"
import { FaFacebookF } from "react-icons/fa"
import FooterLogo from "../images/svgs/footerLog.svg"
import ScrollAnimation from "react-animate-on-scroll"

const FooterCont = () => {
  return (
    <div className="footer-cont">
      <Container>
        <Grid container spacing={{ xs: 2, sm: 7 }} className="footerContainer">
          <Grid item xs={12} sm={6} className="detailGrid">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <FooterLogo className="footerLogo" />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              delay={300}
            >
              <p>
                Jandakot Heli-Co was created with the goal of providing top
                quality, personalised, and professional training.{" "}
              </p>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              delay={500}
            >
              <div className="socials">
                <SocialButton
                  href="https://www.instagram.com/jandakothelico/"
                  icon={<FaInstagram />}
                />
                <SocialButton
                  href="https://www.facebook.com/jandakothelico/"
                  icon={<FaFacebookF />}
                />
              </div>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              delay={300}
            >
              <Map />
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Container>
      <div className="copyRight">
        <p>
          &copy; {new Date().getFullYear()} By Jandakot Heli-Co | Site By{" "}
          <a
            target="_blank"
            style={{ color: "#979ead" }}
            href="https://www.wordofmouthagency.com.au/"
          >
            Word Of Mouth Agency
          </a> | <Link to='/privacy-policy' style={{ color: "#979ead" }}>Privacy Policy</Link>
        </p>
      </div>
    </div>
  )
}

export default FooterCont
